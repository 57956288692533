<template>
  <div class="revenue-box">
    <div v-if="show">
      <div class="title-row row">
        <span class="title">My Revenue</span
        ><el-button class="primary-button" icon="el-icon-lock"
          >Protect this page</el-button
        >
      </div>
      <Revenue-Top-Infor></Revenue-Top-Infor>
      <!-- table -->
      <Revenue-Tables></Revenue-Tables>
    </div>
    <!-- password -->
    <div v-else>
      <RevenuePassword @submit="submit"></RevenuePassword>
    </div>
  </div>
</template>
<script>
import RevenueTopInfor from "./Component/RevenueTopInfor";
import RevenueTables from "./Component/RevenueTables";
import RevenuePassword from "./Component/RevenuePassword";
export default {
  components: { RevenueTables, RevenuePassword, RevenueTopInfor },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    submit() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.revenue-box {
  color: #36425d;
  padding-right: 10px;
  .title-row {
    justify-content: space-between;
    margin-bottom: 30px;
    .title {
      font-family: "Roboto-Bold", sans-serif;
      font-weight: normal;
      font-size: 24px;
      text-align: left;
    }
  }
  .item-row {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .my-sales,
    .my-rentals {
      width: calc(calc(100% - 20px) / 2);
      background-color: #fff;
      .my-content {
        justify-content: space-between;
        padding: 14px 20px 20px 20px;
      }
    }
    .my-title {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 20px;
      text-align: left;
      margin: 0;
      padding: 14px 20px 20px 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 1px solid #e1e9ef;
      }
    }
    .my-sales {
      margin-right: 20px;
      .my-sales-content {
      }
    }
    .my-rentals {
      .my-rentals-content {
      }
    }
  }
}
</style>