<template>
  <div class="top-infor">
    <div class="row item-row">
      <!-- <div class="my-sales">
        <p class="my-title">My Sales</p>
        <div class="my-sales-content my-content row">
          <div>
            <p  class="small-title">Sales Listings</p>
            <span  class="revenue-data">456</span>
          </div>
          <div>
            <p  class="small-title">Sales Transactions</p>
            <p  class="revenue-data">341</p>
          </div>
          <div>
            <p  class="small-title">Sales Commission</p>
            <span  class="revenue-data">$268,987</span>
          </div>
        </div>
      </div> -->
      <div class="my-rentals">
        <p class="my-title">My Rentals</p>
        <div class="my-rentals-content my-content row">
          <div>
            <p class="small-title">Sales Listings</p>
            <span class="revenue-data">456</span>
          </div>
          <div>
            <p class="small-title">Sales Transactions</p>
            <span class="revenue-data">341</span>
          </div>
          <div>
            <p class="small-title">Sales Commission</p>
            <span class="revenue-data">$268,987</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/init.scss";
.top-infor {
  .item-row {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .my-sales,
    .my-rentals {
      width: calc(calc(100% - 20px) / 2);
      background-color: #fff;
      .my-content {
        justify-content: space-between;
        padding: 14px 20px 20px 20px;
      }
    }
    .my-title {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 20px;
      text-align: left;
      margin: 0;
      padding: 14px 20px 20px 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 1px solid #e1e9ef;
      }
    }
    .small-title{
      font-family: $fontRR;
      font-size: 14px;
    }
    .revenue-data{
      font-family: $fontRB;
      font-size: 20px;
    }
    .my-sales {
      margin-right: 20px;
      .my-sales-content {
      }
    }
    .my-rentals {
      .my-rentals-content {
      }
    }
  }
}
</style>