<template>
  <div class="revenue-password row">
      <!-- 输入密码 -->
    <div class="password-box">
      <p class="psd-tip">Password Protection</p>
      <el-divider></el-divider>
      <div class="psd-content">
        <p class="icon-row"><i class="el-icon-lock"></i></p>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="Please enter your email password"
          show-password
          class="inpassword"
        ></el-input>
        <p class="forget-psd"><a href="#">Forget your password</a></p>
        <div class="btn">
          <el-button class="primary-button" @click="submit">Submit</el-button>
        </div>
      </div>
    </div>
    <!-- 忘记密码 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      password: "",
    };
  },
  methods:{
      submit(){
          this.$emit("submit")
      }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "../../../css/init.scss";
.revenue-password {
  width: 100%;
  justify-content: center;
  height: calc(100vh - 350px);
  .password-box {
    width: 450px;
    border-radius: 4px;
    padding: 20px 0;
    background-color: #fff;
    .psd-tip {
      margin-top: 0;
      padding-left: 20px;
      text-align: left;
      font-family: $fontRM;
      font-size: 20px;
    }
    .psd-content {
      padding: 0 20px;
      .forget-psd {
        text-align: right;
        a {
          color: $color1;
        }
      }
      .btn {
        text-align: right;
        .el-button {
          width: 100px;
        }
      }
    }
    .icon-row {
      justify-content: center;
      i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        border-radius: 50%;
        color: $color1;
        background-color: #f4f9fc;
      }
    }
  }
}
</style>