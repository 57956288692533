<template>
  <div class="revenue-table">
    <div class="row table-top-row">
      <div class="tab row">
        <div
          class="tab-pane"
          :class="{ active: tabNumber == 1 }"
          @click="tab(1)"
        >
          Leases
        </div>
        <!-- <div
          class="tab-pane"
          :class="{ active: tabNumber == 2 }"
          @click="tab(2)"
        >
          Sales Listings
        </div> -->
      </div>
      <el-button class="btn" v-show="tabNumber === 1" @click="createLease"
        >+ New Lease</el-button
      >
    </div>
    <div v-show="tabNumber === 1" class="position-btn">
      <Lease-History
        :data="dataLaeses"
        :total="total"
        @pagesChanged="pagesChanged"
      ></Lease-History>
    </div>
  </div>
</template>
<script>
import LeaseHistory from "../../common/LeaseHistory";
import { apiGetLeases } from "../../../API/api";
export default {
  data() {
    return {
      tabNumber: 1,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 5, // 每页显示数量
      dataLaeses: [],
    };
  },
  components: { LeaseHistory },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
        self: true,
      };
    },
  },
  methods: {
    tab(index) {
      this.tabNumber = index;
    },
    createLease() {
      this.$store.commit("setLeaseDetail", false);
      this.$store.commit("setLeaseAgeent", false);
      this.$store.commit("setLeaseSecurityDeposit", false);
      this.$router.push({ name: "create-lease" });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getGetLeases();
    },
    getGetLeases() {
      // if (this.$route.params.id === undefined) return;
      apiGetLeases(this.query).then((res) => {
        this.dataLaeses = res.results;
        this.total = res.count;
      });
    },
  },
  created() {
    this.getGetLeases();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/selefLeases.scss";
.revenue-table {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 4px;
  .position-btn{
    padding: 0 10px 20px 10px;
  }
}
</style>